import React, { useState,useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Header from "../include/header";
import Footer from "../include/footer";
import UserLeftPanel from "../include/UserLeftPanel";
import axios from "axios";
import Global from "../../APIConfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";
import CopyToClipboard from 'react-copy-to-clipboard';

// Fix for default marker icons in React Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const DriverMap = () => {
    let navigate = useNavigate();
        const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);
    const [driverLocations, setDriverLocations] = useState([]);
    
    useEffect(()=>{
        GetDriverList();
    },[]);
    
    const GetDriverList=()=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchDriverlatlng',
            headers:headers1,
            data: {
                
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setDriverLocations(data);
            }
            else
            {
                setDriverLocations();
            }
            setSubloading(false);
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setSubloading(false);
            setLoading(false);
            // console.log(error);
          });
    }

  return (
    <>
        <UserLeftPanel/>
        <main className='home-section'>
            {/* <Header/> */}
            <ToastContainer/>
            {Loading==true?<MainLoader/>:
            <>
    <div style={{ height: '100vh', width: '100%' }}>
      <h1 style={{ textAlign: 'center', margin: '20px 0' }}>Driver Locations</h1>
      <MapContainer
        center={[20.5937, 78.9629]} // Initial map center
        zoom={5} // Initial zoom level
        style={{ height: '80%', width: '100%' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {driverLocations.map((driver) => (
          <Marker key={driver.id} position={driver.position}>
            <Popup>
              <strong>{driver.name}</strong>
              <br />
              Latitude: {driver.position[0]}, Longitude: {driver.position[1]}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
    </>
}
<Footer/>
</main>
</>
  );
};

export default DriverMap;