import React,{useState} from "react";  
import Global from '../../APIConfig';
import { useNavigate,Link } from "react-router-dom";
import AuthUser from "../../AuthUser";


function UserLeftPanel()
{
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const[show,SetShow]=useState('close');
    const items = JSON.parse(localStorage.getItem('user'));
    const user_privilage_string = items.user_privilage_array;
    const user_privilage_array = user_privilage_string.split(',').map(Number);
    const userlogout=()=>{
        logout();
        navigate('/');
    }
    return (
        <div className={`sidebar ${show}`}>
            <div className="logo-details">
                <div className="head_icon" onClick={()=>{SetShow(show=='close'?'show':'close')}}>
                    <i className="bx bx-menu"></i>
                </div>
                <span className="logo_name">Sky Ship</span>
            </div>
            <ul className="nav-links">
                <li>
                    <div className="iocn-link">
                        <a href="#">
                            <i className="bx bx-grid-alt"></i>
                            <span className="link_name">Dashboard</span>
                        </a>
                        <i className="bx bxs-chevron-down arrow"></i>
                    </div>
                    <ul className="sub-menu">
                        <li><Link className="link_name" to={`/Admin-Dash`}>Dashboard</Link></li>
                        <li><a href="/Admin-Dash">Master Dashboard</a></li>
                    </ul>
                </li>
                {items.usertype =='AD' || user_privilage_array.includes(9) ?
                <li>
                    <div className="iocn-link">
                        <a href="#">
                            <i className="bx bx-user"></i>
                            <span className="link_name">Customers</span>
                        </a>
                        <i className="bx bxs-chevron-down arrow"></i>
                    </div>
                    <ul className="sub-menu">
                        <li><Link className="Link_name" to={`/CustomerList/All`}>Customers</Link></li>
                        <li><Link to={`/CustomerList/All`}>All Customers</Link></li>
                        <li><Link to={`/CustomerList/C`}>Credit Customers</Link></li>
                        <li><Link to={`/CustomerList/NC`}>Non Credit Customers</Link></li>
                        <li><Link to={`/CustomerList/U`}>Contract Customers</Link></li> 
                    </ul>
                </li>
                :
                ""
                }
                {items.usertype =='AD' || user_privilage_array.includes(10) ?
                <li>
                    <div className="iocn-link">
                        <a href="#">
                            <i className="bx bx-buildings"></i>
                            <span className="link_name">HUB Management</span>
                        </a>
                        <i className="bx bxs-chevron-down arrow"></i>
                    </div>
                    <ul className="sub-menu">
                        <li><Link className="link_name" to={`/HubList`}>HUB Management</Link></li>
                        <li><Link className="" to={`/HubList`}>All Hub</Link></li>
                        <li><Link className="" to={`/HubAdd`}>Add New Hub</Link></li>
                    </ul>
                </li>
                :
                ""
                }
                {items.usertype =='AD' || user_privilage_array.includes(11) ?
                <li>
                    <div className="iocn-link">
                        <a href="#">
                            <i className="bx bx-user-pin"></i>
                            <span className="link_name">Agent Management </span>
                        </a>
                        <i className="bx bxs-chevron-down arrow"></i>
                    </div>
                    <ul className="sub-menu">
                        <li><Link className="link_name" to={`/AgentList/All`}>Agent Management</Link></li>
                        <li><Link className="" to={`/AgentList/All`}>Agent List</Link></li>
                        <li><Link className="" to={`/AgentAdd`}>Add New Agent</Link></li>
                    </ul>
                </li>
                :
                ""
                }
                {items.usertype =='AD' || user_privilage_array.includes(12) ?
                <li>
                    <div className="iocn-link">
                        <a href="#">
                            <i className="bx bx-group"></i>
                            <span className="link_name">Staff Management</span>
                        </a>
                        <i className="bx bxs-chevron-down arrow"></i>
                    </div>
                    <ul className="sub-menu">
                        <li><Link className="link_name" to={`/StaffList`}>Staff Management</Link></li>
                        <li><Link to={`/StaffList`}>Staff List</Link></li>
                        <li><Link to={`/StaffAdd`}>Add Staff</Link></li>
                        <li><Link to={`/Privillages`}>Set Staff Privillages</Link></li>
                    </ul>
                </li>
                :
                ""
                }
                {items.usertype =='AD' || user_privilage_array.includes(13) ?
                <li>
                    <div className="iocn-link">
                        <a href="#">
                            <i className="bx bx-cycling"></i>
                            <span className="link_name">Driver Management</span>
                        </a>
                        <i className="bx bxs-chevron-down arrow"></i>
                    </div>
                    <ul className="sub-menu">
                        <li><Link className="link_name" to={`/DriverList`}>Driver Management</Link></li>
                        <li><Link to={`/DriverList`}>Driver List</Link></li>
                        <li><Link to={`/DriverAdd`}>Add Driver</Link></li>
                    </ul>
                </li>
                :
                ""
                }
                <li>
                <div className="profile-details">
                <div className="profile-content">
                <img src={`${Global.logoUrl}${items.profile_image}`} alt="profileImg" />
                </div>
                <div className="name-job">
                <div className="profile_name">{items.name}</div>
                <div className="job">{items.company}</div>
                </div>
                <i className="bx bx-log-out" onClick={()=>userlogout()}></i>
            </div>
                </li>
            </ul>
        </div>
    );

}
export default UserLeftPanel;